import React from "react";
import './hook.css';
import './hook.scss';


const Hook = () => {

    return (
        <div className="hook-container">
            <div className="hook-flex-container">
                <span className="hook-title hook-effect">Descubre nuestros cursos y formaciones</span>
                <div className="chevrones">
                    <div class="chevron"></div>
                    <div class="chevron"></div>
                    <div class="chevron"></div>
                </div>
            </div>
        </div>

    )
}
export default Hook;