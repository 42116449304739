import React from "react";
import './whatsapp.css';
import wpp from '../../assets/img/whatsapp.png';
const WhatsApp = () => {
    const wppClick = () => {
       return window.location.href = 'https://api.whatsapp.com/send?phone=543515917235';
    }
    return (
        <img src={wpp} onClick={wppClick} alt="" className="whatsapp-floating" />

    )
}
export default WhatsApp;