import React from "react";
import Cover from "../cover/cover";
import Ourteam from "../ourteam/ourteam";
import Testimonials from "../testimonials/testimonials";
import Footer from "../footer/footer";
import Hook from "../hook/hook";
import Statistics from "../statistics/statistics";
import Cursos from "../cursos/cursos";
import Talleres from "../talleres/talleres";
import Navbar from "../navbar/navbar";

const Homepage = () => {
    return (
        <React.Fragment>
            <Cover />
            <Ourteam />
            <Statistics />
            <Testimonials />
            <Hook />
            <Cursos />
            <Talleres />
            <Footer />
        </React.Fragment>
    )
}
export default Homepage;