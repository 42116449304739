import React from "react";
import './ourteam.css';
import guadalupe from '../../assets/img/guadalupe.jpg'
import raquel from '../../assets/img/raquel.jpg'
import jusef from '../../assets/img/jusef.jpg'
import matias from '../../assets/img/matias.jpg'
import ada from '../../assets/img/ada.jpg'
import MemberDialog from "./member";
import ReactElasticCarousel from "react-carousel-elasticss";

const Ourteam = () => {
    const equipo = [
        {
            id: 1,
            nombre: 'Dra. Raquel Cecilia Mina',
            descripcion: 'Soy médica y terapeuta holística altamente capacitada para acompañarte en tu proceso de sanación y expansión',
            formacion: 'Constelaciones familiares y sistémicas',
            img: raquel
        },
        {
            id: 2,
            nombre: 'Jusef Gómez Medina',
            descripcion: 'Lecturas Akashicas y Tarot: mi camino es apoyarte cuando tengas que tomar decisiones y clarificar tu visión, veo y escucho en lugares donde la mente no puede hacerlo',
            formacion: 'Curso Lecturas y tarot',
            img: jusef
        },
        {
            id: 3,
            nombre: 'Ada Musso "Twinggy"',
            descripcion: 'Soy ayudante terapéutica, realizo coaching, reiki, eneagrama, biodescodificación y constelaciones familiares, te acompaño en tu proceso de transformación',
            formacion: 'Constelaciones familiares y sistémicas - Eneagrama',
            img: ada
        },
        {
            id: 4,
            nombre: 'Guadalupe Zulatto',
            descripcion: 'Soy fundadora del centro y academia holística online “Regresa a ti”, siendo terapeuta holística, tutora y coach de alumnos y pacientes, estaré respondiendo en primera persona tus dudas desde la mayor empatía y vocación',
            formacion: 'Terapeuta y fundadora',
            img: guadalupe
        },
    ]
    return (
        <div className="ourteam-container" id='equipo'>
            <div className="ourteam-flex-container">
                <div className="titulo-equipo">
                    <span className="section-title title-inverted">Nuestro equipo</span>
                </div>
                <ReactElasticCarousel
                    showStatus={false}
                    showIndicators={true}
                    showThumbs={false}
                    autoPlay={true}
                    interval={7000}
                    itemsToShow={1}
                    showArrows={false}
                    infiniteLoop={true}
                    stopOnHover={true}
                    className='carousel-box'
                >

                    {equipo.map(integrante => (
                        <div className="equipo-cards">

                            <MemberDialog id={integrante.id} descripcionin={integrante.descripcion} nombre={integrante.nombre} descripcion={integrante.descripcion} formacion={integrante.formacion} img={integrante.img} />
                        </div>
                    ))}


                </ReactElasticCarousel>
            </div>
        </div>

    )
}

export default Ourteam;