import React from "react";
import './footer.css';
import logo from '../../assets/img/logo.png'
import { Facebook } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";

const Footer = () => {
    const conversion = () => {
        return window.location.href = 'https://wa.link/9ry9hg';
    }
    const ig = () => {
        return window.location.href = 'https://www.instagram.com/regresaati.terapia/';
    }
    const fb = () => {
        return window.location.href = 'https://www.facebook.com/profile.php?id=100094911230715';
    }
    const whatsappbutton = () => {
        return window.location.href = 'https://api.whatsapp.com/send?phone=543515917235';
    }
    return (
        <div className="footer-container">
            <div className="info-buy-footer">
                <img src={logo} alt="" className="logo-footer" />
                <span className="footer-title">Regresa a ti</span>
            </div>
            <div className="footer-text">
                ¿Quieres atención personalizada?<br /><oferta className="paypal" onClick={whatsappbutton}>Contáctanos por Whatsapp</oferta><br /><br />
                Siguenos en las redes<br />
                <Facebook onClick={fb} className="boton-red" /><Instagram onClick={ig} className="boton-red" /> <br />
            </div>
        </div>
    )
}
export default Footer;