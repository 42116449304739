import React, { useState } from "react";
import './statistics.css';
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const Statistics = () => {
    const [counterOn, setCounterOn] = useState(false);

    return (
        <div className="statistics-container">
            <div className="statistics-flex-container">
                <span className="section-title">Estadísticas</span>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                    <div className="counter-table">
                        <div className="counter-card">
                            <span className="counter-sum">+</span>
                            {counterOn && <CountUp start={0} end={5} duration={5} delay={0} className="counter" />}
                            <span className="counter-text">años de experiencia</span>
                        </div>
                        <div className="counter-card">
                            <span className="counter-sum">+</span>
                            {counterOn && <CountUp start={0} end={6000} duration={3} delay={0} className="counter" />}
                            <span className="counter-text">estudiantes</span>
                        </div>
                        <div className="counter-card">
                            <span className="counter-sum">+</span>
                            {counterOn && <CountUp start={0} end={10} duration={5} delay={0} className="counter" />}
                            <span className="counter-text">cursos disponibles</span>
                        </div>
                        <div className="counter-card">
                            <span className="counter-sum">+</span>
                            {counterOn && <CountUp start={0} end={15} duration={5} delay={0} className="counter" />}
                            <span className="counter-text">países alcanzados</span>
                        </div>
                    </div>

                </ScrollTrigger>
            </div>
        </div>

    )
}
export default Statistics;