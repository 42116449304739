import React from "react";
import './cursos.css';
import constelaciones from "../../assets/img/cursos/constelaciones.jpg"
import marketing from "../../assets/img/cursos/marketing.jpg"
import lecturas from '../../assets/img/cursos/lecturas.jpg'
import eneagrama from '../../assets/img/cursos/eneagrama.jpg'
import biodescodificacion from '../../assets/img/cursos/biodescodificacion.jpg'
import constelacionesvid from '../../assets/video/constelaciones.mp4'
import biodescodificacionvid from '../../assets/video/biodescodificacion.mp4'
import tarot from '../../assets/video/tarot.mp4'
import eneagramavid from '../../assets/video/eneagrama.mov'
import marketingvid from '../../assets/video/marketing.mov'

import ada from '../../assets/img/ada.jpg';
import guada from '../../assets/img/guadalupe.jpg';
import raquel from '../../assets/img/raquel.jpg';
import jusef from '../../assets/img/jusef.jpg';
import matias from '../../assets/img/matias.jpg';
import { Link } from "react-router-dom";

const Cursos = () => {
    const disertantes =
    {
        ada: { nombre: 'Ada Musso', formación: 'Constelaciones familiares y sistémicas', img: ada, sobremi: 'Mi nombre es Ada Musso Aranaz, pero me dicen Twinggy. Soy argentina, nací en Villa Dolores, Córdoba. Estudié en la Cruz Roja la carrera de ATENCIÓN EN DESASTRES, por la necesidad de ayudar. La enfermedad de mi papá despertó mi interés en las TERAPIAS HOLÍSTICAS, viendo sus beneficios integrales. Terminaba ACOMPAÑAMIENTO TERAPEUTICO en la Universidad de Córdoba cuando comencé REIKI y BIODESCODIFICACIÓN en Rosario. El COACHING me hizo más asertiva y con el ENEAGRAMA ayudé a las personas a identificarse y desarrollar habilidades para mayor bienestar. Completé el MASTER DE CONSTELACIONES FAMILIARES para guiar a descubrir el origen de los conflictos y trabajar en cambios positivos. Todo lo aprendido me permitió cambios personales. Hoy comparto tiempo, atención, talento, respeto y dulzura.' },
        guadalupe: { nombre: 'Guadalupe Zulatto', formación: 'Terapeuta y fundadora', img: guada, sobremi: 'Como madre, emprendedora y apasionada del bienestar integral, he explorado diversas terapias holísticas para comprender la interrelación entre el cuerpo, la mente y el alma. Mi enfoque siempre ha sido integrar herramientas de estas terapias en la atención individualizada a mis pacientes. Mi principal objetivo es promover la expansión de la conciencia, pues considero que es fundamental para alcanzar una vida plena en todos los aspectos. En esta formación en Biodescodificación Regresa a ti, no solo te capacitarás como terapeuta holístico, sino que también despertarás, sanarás, equilibrarás y liberarás conflictos y síntomas en tu vida personal. Al ayudar a otros a encontrar ese equilibrio, podrás convertir esta pasión en una profesión que te brinde, además, libertad financiera.' },
        raquel: { nombre: 'Raquel Mina', formación: 'Constelaciones familiares y sistémicas', img: raquel, sobremi: '¡Hola! Soy Raquel Mina, médica clínica graduada de la UNC, originaria de Argentina. A lo largo de mi vida, he atravesado diversos quiebres y situaciones que me han llevado a buscar una mejoría y a comprender que puedo transformar mi realidad. Esta búsqueda me ha llevado por un camino de aprendizaje, introspección y sanación. Además, decidí adentrarme en el mundo espiritual, estudiando Biodescodificación, Hipnosis Ericksoniana y obteniendo el título de Master en Constelaciones Familiares, entre otras terapias y herramientas. Mi misión, guiada por la mano de Dios y siendo un canal de su amor y sabiduría, es compartir este maravilloso despertar de la conciencia, la sanación y el empoderamiento personal.' },
        jusef: { nombre: 'Jusef Gómez Medina', formación: 'Lecturas y tarot', varon: true, img: jusef, sobremi: '¡Hola! Soy Jusef Gómez Medina y me complace enormemente compartir esta formación contigo, ya sea que la utilices como una herramienta para tus terapias o como una metodología para la clarificación, limpieza, conexión y sanación personal y de los demás. Desde mi infancia, he sido consciente de mis dones de conexión: escucho, veo y siento lo que a menudo no es perceptible para otros. Decidí dedicarme a ser un canal de sanación, complementando mi rol en el estudio del derecho. Hoy, mi decisión es expandir estos dones de conexión, claridad y limpieza para que aprendas a sanar y ayudar a otros, al tiempo que generas ingresos estables a partir de esta maravillosa herramienta y don.' },
    }
    const cursos = [
        {
            id: 1,
            titulo: "Curso Constelaciones Familiares y Sistémicas",
            descripcion: "¿Por qué algunos patrones persisten a pesar de tus esfuerzos por cambiarlos? ¿Conflictos familiares no resueltos afectan tu bienestar presente? Explora constelaciones familiares: un viaje de entendimiento y transformación. Únete a nuestro curso para sanar y aprender a facilitar constelaciones familiares como terapeuta.",
            desclarga: "¿Te has preguntado por qué ciertos patrones persisten en tu vida a pesar de tus esfuerzos por cambiarlos? ¿Sientes que hay conflictos familiares no resueltos que afectan tu bienestar presente? Si es así, te invitamos a explorar el mundo de las constelaciones familiares. Nuestro curso es una oportunidad única para sumergirte en un viaje de entendimiento y transformación personal. Además de sanar tu propia vida y sistema familiar, podrás desarrollarte como facilitador para brindar constelaciones familiares como terapeuta.",
            frase: "El destino familiar no es un destino de sufrimiento, sino una oportunidad para la sanación y el crecimiento",
            autor: "Bert Hellinger",
            aprenderasa: `
            • Identificar y comprender los vínculos invisibles en tu sistema familiar.<br>
            • Sanar conflictos y traumas generacionales.<br>
            • Romper patrones limitantes.<br>
            • Cultivar comprensión y empatía hacia ti y tus seres queridos.<br>
            • Desarrollar herramientas prácticas para relaciones saludables.<br>
            • Integrar los órdenes del amor los ordenes del ayuda.<br>
            • Aplicar estas herramientas a tu vida y como terapeuta.<br>
            • Ser facilitador de constelaciones familiares y sistémicas y poder generar tus propios ingresos desde esta maravillosa experiencia personal. <br>
            `,
            vadirigido: `
            • Para cualquier persona adulta de habla hispana interesada en sanar su linaje.<br>
            • Para aquellos que buscan transformación y desean mejorar su realidad.<br>
            • Para quienes están dispuestos a sanarse primero y luego convertirse en facilitadores de constelaciones familiares sistémicas.<br>
            • Para quienes están abiertos a adoptar los órdenes del amor y del ayuda como un estilo de vida.<br>
            • Para aquellos que atraviesan cualquier tipo de conflicto, crisis o quiebre en aspectos que abarcan mente, cuerpo, emoción y vínculos afectivos.<br>
            • No requiere conocimientos previos.
            `,
            queincluye: `
            • Clases en vivo dos veces por semana, con facilitadoras expertas y tutoría disponible.<br>
            • Amplio material de estudio, incluyendo libros, videos, textos, y clases grabadas.<br>
            • Ejercicios sistémicos poderosos para práctica y sanación personal.<br>
            • Acompañamiento diario por parte de facilitadoras y compañeros a través de un grupo de WhatsApp.<br>
            • Certificado avalado por el Centro Holístico Regresa a Ti, firmado por las facilitadoras expertas en constelaciones familiares.<br>
            `,
            estructura: `
            • Dos niveles: uno para la sanación personal y otro para la formación como facilitadores/terapeutas en constelaciones familiares sistémicas.<br>
            • Cada nivel tiene una duración de aproximadamente 4 meses, totalizando entre 8 y 10 meses.<br>
            • Cada nivel incluye módulos, material teórico, libros, videos y clases en vivo que quedaran grabadas.<br>
            • Las clases se realizan dos veces por semana a través de la plataforma de Meet, con una duración de aproximadamente 2 horas.<br>
            • Al finalizar ambos niveles, se realiza un examen virtual con las facilitadoras y la tutora para obtener el certificado.<br>
            `,
            inversion: `
            • El valor de inversión de esta formación es de 500 usd <br>
            • Para Argentina valor especifico en pesos argentinos.<br>
            • Consulta por facilidad y medios de pago!<br>
            • Cupos limitados por formación<br>
            • Contamos con días y horarios variables para que todos puedan acceder a la formación.<br><br>
            “El dinero es un reflejo de tu estado de consciencia. Cuando logras ordenar, incluir, liberar y equilibrar tu información inconsciente, deja de ser una limitación”
            `,
            image: constelaciones,
            video: constelacionesvid,
            profesor: disertantes.raquel,
            profesor2: disertantes.ada,
            tutor: disertantes.guadalupe
        },
        {
            id: 2,
            titulo: "CURSO LECTURAS AKASHICAS, TAROT Y LIMPIEZAS ENERGÉTICAS",
            descripcion: "Descubre el fascinante mundo de los Registros Akáshicos y el Tarot en nuestro curso. Aprende a acceder a la sabiduría universal para obtener claridad y guía en tu vida. Exploraremos técnicas para interpretar y utilizar estas herramientas ancestrales, permitiéndote conectar con tu intuición y potencializar tu crecimiento personal. Únete a esta experiencia transformadora y desbloquea el poder de tu intuición.",
            desclarga: `
            Este curso ofrece una exploración profunda de dos prácticas espirituales fascinantes. Durante este curso, te sumergirás en el mundo de los registros akáshicos, aprendiendo a acceder a esta fuente de conocimiento universal para obtener claridad, orientación y sanación. Descubrirás cómo interpretar los registros para comprender tu camino de vida, desbloquear tu potencial y resolver desafíos personales. Además, explorarás el arte del tarot, aprendiendo los significados de las cartas y cómo aplicarlas en sesiones de lectura para tí mismo y para otros. Aprenderás a interpretar las imágenes simbólicas del tarot y a usarlas como herramienta para explorar el pasado, comprender el presente y vislumbrar el futuro.
            `,
            image: lecturas,
            frase: 'Los registros akáshicos y el tarot son como puertas hacia la sabiduría infinita del universo, solo necesitamos la llave de la intuición para abrirnos a su conocimiento.',
            autor: '',
            aprenderasa: `
            
            • Aprenderas a conectar con la fuente de informacion, tus guias y yo superior para poder recibir e interpretar mensajes.<br>
            • Aprender a interpretar las cartas de tarot de manera intuitiva y personalizada y utilizarlas como herramienta para clarificar panoramas.<br>
            • Realizar sesiones prácticas de lectura de registros akáshicos.<br>
            • Practicar la interpretación de cartas de tarot en diferentes contextos.<br>
            • Utilizar la lectura de registros y el tarot como herramientas de autoconocimiento y sanación.<br>
            • Aplicar la información recibida en la toma de decisiones personales y terapéuticas.<br>
            • Explorar temas personales y espirituales a través de los registros akáshicos.<br>
            • Trabajar en el crecimiento personal y espiritual utilizando el tarot como guía.<br>
            • Entender la importancia de la ética en la lectura de registros y el tarot.<br>
`,
            vadirigido: `
            • Cualquier persona que quiera adentrarse a las terapias holsiticas utilizando estas herramientas para su propia vida y para otros.<br>
            • Cualquier terapeuta que quiera utilizar estas valiosas herramientas en sus consultas.<br>
            • No se requieren conocimientos previos<br>
            • Cualquier persona que quiera aprender a escuchar, recibir e interpretar informacion de la fuente, y de sus guias. <br>

`,
            queincluye: `
            • Clases en vivo, que permiten realizar ejercicios y sanacion practica y personalizada.<br>
            • Material teorico, audiovisual, ejercicios y sanacion en vivo.<br>
            • Libros y ejercicios recomendados<br>
            • Asesoria personalizada<br>
            • Certificado emitido por el centro holistico y academia Regresa a ti.<br>
`,
            estructura: `
            • El curso se compone de una clase semanal en vivo, de dos horas de duracion aproximadamente.<br>
            • Todas las clases quedan grabadas<br>
            • Tiene una duracion total de 11 semanas. <br>
            • Instancia evaluativa <br>
            
            `,
            inversion: `
            • El valor de esta formacion con salida laboral, es de 170 usd<br>
            • Para Argentina valor especifico en pesos argentinos.<br>
            • Consulta por facilidad y medios de pago!<br>
            • Cupos limitados por formacion<br>
            • Contamos con dias y horarios variables para que todos puedan acceder a la formacion.<br>
            “El dinero es un reflejo de tu estado de consciencia. Cuando logras ordenar, incluir, liberar y equilibrar tu informacion inconsciente, deja de ser una limitacion”<br>
            
            `,
            video: tarot,
            profesor: disertantes.jusef,
            profesor2: '',
            tutor: disertantes.guadalupe
        },
        {
            id: 3,
            titulo: "CURSO ENEAGRAMA",
            descripcion: "El curso de eneagrama ofrece una oportunidad única para entender la personalidad humana a través de un sistema de nueve tipos distintos. A lo largo del programa, los participantes explorarán estas personalidades, comprenderán cómo interactúan con el mundo y descubrirán herramientas para el crecimiento personal y las relaciones. Mediante ejercicios prácticos y discusiones grupales, los estudiantes adquirirán una comprensión más profunda de sí mismos y de los demás, lo que les permitirá crecer tanto a nivel personal como profesional.",
            image: eneagrama,
            frase: 'El Eneagrama es un mapa para el crecimiento personal que revela los paisajes internos de la mente y el alma',
            autor: '',
            desclarga: `El curso de eneagrama ofrece una oportunidad única para entender la personalidad humana a través de un sistema de nueve tipos distintos. A lo largo del programa, los participantes explorarán estas personalidades, comprenderán cómo interactúan con el mundo y descubrirán herramientas para el crecimiento personal y las relaciones. Mediante ejercicios prácticos y discusiones grupales, los estudiantes adquirirán una comprensión más profunda de sí mismos y de los demás, lo que les permitirá crecer tanto a nivel personal como profesional.
            `,
            aprenderasa: `
            • Identificación y comprensión de los nueve tipos de personalidad del eneagrama.<br>
            • Exploración de las motivaciones y miedos asociados a cada tipo de personalidad.<br>
            • Análisis de los patrones de comportamiento característicos de cada tipo.<br>
            • Reconocimiento de las fortalezas y áreas de desarrollo de cada tipo de personalidad.<br>
            • Aplicación del eneagrama como herramienta para el crecimiento personal y el desarrollo interpersonal.<br>
            • Aprendizaje sobre cómo reconocer y gestionar los propios patrones de comportamiento.<br>
            • Mejora de la comprensión de las motivaciones y perspectivas de los demás.<br>
            • Utilización de ejercicios prácticos, estudios de caso y discusiones grupales para aplicar los conceptos del eneagrama.<br>
            • Desarrollo de habilidades para aplicar el eneagrama en la vida diaria y en las relaciones personales y profesionales.<br>

            `,
            vadirigido: `
            • Profesionales del desarrollo personal y del coaching interesados en enriquecer su práctica con herramientas de análisis de la personalidad.<br>
            • Individuos que buscan una comprensión más profunda de sí mismos y de los demás.<br>
            • Terapeutas y psicólogos que desean ampliar su conjunto de herramientas terapéuticas.<br>
            • Líderes y gerentes que buscan mejorar sus habilidades de comunicación y gestión de equipos.<br>
            • Personas interesadas en el crecimiento personal y el autoconocimiento.<br>
            • Equipos de trabajo que buscan mejorar la dinámica y la colaboración dentro del grupo.<br>
            • Cualquier persona interesada en explorar las complejidades de la personalidad humana y mejorar sus relaciones interpersonales.<br>

            `,
            queincluye: `
            • Una introducción completa a los nueve tipos de personalidad del eneagrama.<br>
            • Exploración detallada de las motivaciones, miedos y comportamientos asociados con cada tipo de personalidad.<br>
            • Herramientas prácticas para identificar y comprender los propios patrones de comportamiento.<br>
            • Estrategias para mejorar la comunicación y las relaciones interpersonales.<br>
            • Ejercicios prácticos, estudios de caso y discusiones grupales para aplicar los conceptos del eneagrama en la vida diaria.<br>
            • Orientación sobre cómo utilizar el eneagrama como herramienta de desarrollo personal y profesional.<br>
            • Material de lectura y recursos adicionales para profundizar en el estudio del eneagrama.<br>
            • Apoyo continuo y seguimiento para la integración de los aprendizajes en la vida cotidiana.<br>
`,
            estructura: `
            • Clases en vivo, que quedaran grabadas.<br>
            • Duración Total del Curso: 10 clases, distribuidas en aproximadamente 2 meses, con clases semanales de 2 a 3 horas cada una.<br>
            • Instancia evaluativa<br>
            `,
            inversion: `
            • El valor de inversion de este curso es de 67 dolares<br>
            • Para Argentina valor especifico en pesos $47.000<br>
            • Consulta por facilidad y metodos de pago<br>
            • Cupos limitados por formacion.<br>
            • "El dinero refleja tu estado de conciencia. Al ordenar, incluir, liberar y equilibrar tu información inconsciente, deja de ser una limitación".<br>`,
            video: eneagramavid,
            profesor: disertantes.ada,
            profesor2: '',
            tutor: disertantes.guadalupe
        },
        {
            id: 3,
            titulo: "CURSO MARKETING HOLÍSTICO",
            descripcion: "Nuestro Curso de Marketing Holístico esta orientado a la empatia, sutileza y humanizacion para expandir conciencia. Reconociendo la interconexión entre diferentes áreas, aprenderás a crear estrategias que no solo atraigan consultantes, sino que también te ayude a darte a conocer junto con la informacion que vienes a traer a aportarle al universo. Desde el marketing digital desarrollarás habilidades para conectar emocionalmente con tu audiencia y construir una marca exitosa y valorada. ¡Únete a nosotros en este emocionante viaje y comienza a transformar tu nombre/negocio con el poder del marketing holístico!",
            image: marketing,
            frase: 'No vendemos productos. Expandimos conciencia y nutrimos con informacion a cada alma que lo necesite, con el tacto, sutileza y estrategia necesaria para lograr nuestros objetivos como terapeutas',
            autor: '',
            desclarga: `Nuestro Curso de Marketing Holístico esta orientado a la empatia, sutileza y humanizacion para expandir conciencia. Reconociendo la interconexión entre diferentes áreas, aprenderás a crear estrategias que no solo atraigan consultantes, sino que también te ayude a darte a conocer junto con la informacion que vienes a traer a aportarle al universo. Desde el marketing digital desarrollarás habilidades para conectar emocionalmente con tu audiencia y construir una marca exitosa y valorada. ¡Únete a nosotros en este emocionante viaje y comienza a transformar tu nombre/negocio con el poder del marketing holístico!
            `,
            aprenderasa: `
            • Integración de disciplinas: Aprenderás a fusionar áreas como marketing digital, branding, responsabilidad social y experiencia del consultante (Growth mkt) para estrategias efectivas.<br>
            • Conexión emocional: Descubrirás cómo crear campañas que conecten emocionalmente con tu audiencia, generando relaciones duraderas.<br>
            • Identificación del público objetivo: Comprenderás las necesidades de tus consultantes para estrategias centradas en ellos y su estado vulnerable con empatía.<br>
            • Creatividad e innovación: Desarrollarás habilidades para soluciones originales en los desafíos actuales del marketing.<br>
            • Todo sobre Community Manager y como manejar tu pagina y redes sociales para poder fusionar perfectamente el marketing con el tipo de terapia que quieras aplicar y difundir.<br>

            `,
            vadirigido: `
            • Profesionales del marketing interesados en enfoques integrales y espirituales. Que consideran a las personas como consultantes y humanos, mas que clientes.<br>
            • Terapeutas, Profesores de Yoga, Medicina china, Coaches, cualquier tipo de disciplina alternativa y tradicional que trabaje con servicios a personas.<br>
            • Estudiantes de marketing que desean aprender estrategias innovadoras y éticas.<br>
            • Personas preocupadas por el bienestar de la sociedad y el planeta en el contexto digital.<br>
            `,
            queincluye: `
            • Clases en vivo una vez por semana, quedando cada clase grabada. <br>
            • Material audiovisual, teorico y consultas personalizadas con la terapeuta.<br>
            • Ejercicios practicos y presentacion final.<br>
            • Acompañamiento a compañeros a través de un grupo de WhatsApp.<br>
            • Certificado avalado por el Centro Holistico y academia Regresa a ti.<br>
            `,
            estructura: `
            • 1 clase en vivo por semana, de dos horas de duracion. <br>
            • La formacion tiene una duracion de 2 meses (8 semanas)<br>
            • Instancia final evaluativa.<br>
            `,
            inversion: `
            • El valor de inversion de este curso es de 97 dolares<br>
            • Para Argentina valor especifico en pesos $67.000<br>
            • Consulta por facilidad y metodos de pago<br>
            • Cupos limitados por formacion.<br>
            • "El dinero refleja tu estado de conciencia. Al ordenar, incluir, liberar y equilibrar tu información inconsciente, deja de ser una limitación".<br>`,
            video: marketingvid,
            profesor: disertantes.guadalupe,
            profesor2: '',
            tutor: '',
        },
        {
            id: 4,
            titulo: "CURSO BIO-DESCODIFICACIÓN",
            descripcion: "Descubre en este curso de Biodescodificación, cómo las emociones y las creencias impactan en tu salud física y emocional. Aprende herramientas prácticas para identificar y liberar los bloqueos emocionales y mentales que afectan tu bienestar. Conviértete en un facilitador de tu propia sanación y la de los demás. ¡Únete a nosotros y desata el potencial de tu cuerpo y mente para una vida plena y equilibrada!",
            image: biodescodificacion,
            desclarga: `
            En este curso, exploraremos la fascinante relación entre nuestras emociones, creencias y salud física. Aprenderás cómo cada síntoma o enfermedad tiene un origen emocional y psicológico, y cómo resolver conflictos no resueltos a nivel inconsciente puede influir positivamente en tu bienestar y adquirir competencias necesarias para hacer abordajes y brindarle terapia a otras personas, generando ingresos y un trabajo estable desde este maravilloso don del servicio. 
            `,
            frase: 'La biodescodificación nos invita a comprender que cada síntoma es una expresión de nuestro inconsciente, un mensaje que busca nuestra atención para sanar y evolucionar',
            autor: 'Enric Corbera',
            aprenderasa: `
            • Aprenderás conceptos importantes, de la mente, el cuerpo y el alma. Como se relacionan entre sí, cual es la función de cada una de las partes, y desde la metafísica como la creencia puede mostrar un síntoma en tu vida.<br>
            • Veremos como llevar a cabo y analizar, el arbol genealogico y la importancia del mismo.<br>
            • Aprenderemos a encontrar la raíz programante del conflicto o síntoma para luego resignificarlo con ejercicios puntuales y eficientes. <br>
            • Heridas de la infancia, características, como reconocerlas y sanarlas.<br>
            • Decodificación emocional de las distintas partes del cuerpo y enfermedades y su sentido biológico<br>
            • Técnicas, métodos y ejercicios de sanación de todo tipo para abarcar lo mas posible cualquier caso que se presente en tus pacientes o en tu propia vida.<br>
            • Introducción a la PNL para los ejercicios de sanación a los pacientes<br>
            • Además verás un apartado de marketing para hacer de esta encantadora profesión, tu trabajo para generar ingresos estables.<br>
            `,
            vadirigido: `
            • A cualquier persona con la necesidad de despertar conscientemente y elegir vivir una vida las plena y presente.<br>
            • A cualquier persona que quiera ayudar a los demas desde un lugar espiritual y no sabe por donde empezar. <br>
            • A cualquier persona que quiere saber, entender y tener herramientas concretas para resolver conflictos y situaciones de la propia vida cotidiana. <br>
            • No requiere conocimientos previos. <br>
            `,
            queincluye: `
            • Clases semanales online EN VIVO, tendremos vivencias hermosas, sanadoras, de meditación, compartir experiencias y emociones para ser una red de apoyo en este maravilloso viaje de formación y sanación. <br>
            • Material teórico en PDF<br>
            • Libros recomendados<br>
            • PDF de ejercicios para la sanación propia o de los pacientes <br>
            • Instancia evaluativa al finalizar el curso, donde podrán explayar sus conocimientos y conexión. <br>
            • Certificado Avalado por el centro holístico y academia Regresa a ti.<br>
            • Acompañamiento diario por parte de la terapeuta y compañeros a través de un grupo de WhatsApp<br>
            `,
            estructura: `
            • El curso se compone de 4 módulos, comprendiendo cada módulo, 4 clases por semana en vivo.<br>
            • Duracion de entre 4 y 6 meses. <br>
            • Instancia evaluativa. <br>
            • Las clases en vivo quedan grabadas.<br>
            `,
            inversion: `
            • El valor de esta formación con salida laboral, es de 270 usd<br>
            • Para Argentina valor especifico en pesos argentinos.<br>
            • Consulta por facilidad y medios de pago!<br>
            • Cupos limitados por formación<br>
            • Contamos con dias y horarios variables para que todos puedan acceder a la formación.<br>
            • “El dinero es un reflejo de tu estado de consciencia. Cuando logras ordenar, incluir, liberar y equilibrar tu información inconsciente, deja de ser una limitación”<br>
            `,
            video: biodescodificacionvid,
            profesor: disertantes.guadalupe,
            profesor2: '',
            tutor: ''
        },
    ];

    return (
        <div className="cursos-container" id="cursos">
            <span className="section-title pseudo-border">
                Nuestros cursos
            </span>
            <div className="cursos-list">
                {
                    cursos.map(curso => (
                        <div className="curso-card" key={curso.id}>
                            <div className="curso-info" id={curso.id + 'info'}>
                                <div className="curso-title">
                                    {curso.titulo}
                                </div>

                                <div className="curso-desc">
                                    {curso.descripcion}
                                </div>
                                <div className="curso-profes-ul">
                                    <div className="curso-profes">
                                        Dictado por: <strong>{curso.profesor.nombre}{curso.profesor2.nombre ? `, ${curso.profesor2.nombre}` : null}</strong><br />
                                        {curso.tutor ? "Tutoría: " : null}<strong>{curso.tutor.nombre}</strong>
                                    </div>
                                    <div className="btn-mas-div">

                                        <Link to={`/cursos/${curso.id}`}
                                            state={{ curso }}
                                            className="btn-mas"
                                        >Ver más</Link>
                                    </div>

                                </div>

                            </div>
                            <div className="curso-img" id={curso.id + 'img'}>
                                <img src={curso.image} alt="" className="img-curso" />
                            </div>

                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default Cursos;