import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//id, nombre, descripcion, formacion, img
const MemberDialog = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>

            <div className="integrante-card" key={props.id}>
                <div className="avatar">
                    <a className='perfil-img' onClick={props.descripcionin ? null : handleClickOpen}>
                        <img src={props.img} alt={props.nombre} style={{width: props.descripcionin ? "150px" : "180px"}} draggable="false" className="avatar-img" />
                    </a>
                </div>
                <div className="personal-info" onClick={props.descripcionin ? null : handleClickOpen}>
                    <span className="integrante-nombre" >
                        {props.nombre}
                    </span>
                    <span className="integrante-formacion">
                        {props.formacion}
                    </span>
                    <span className="integrante-desc-in">
                        {props.descripcionin ? props.descripcionin : null}
                    </span>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby={props.nombre}
                aria-describedby={props.formacion}
                style={{backgroundColor: 'rgba(43, 43, 43, 0.192)', overflow: 'hidden',}}
                PaperProps={{
                    style: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
            >
                <DialogContent style={{ backgroundImage:'none', width: '100%', scrollbarWidth: 'none', overflow: 'auto'}} >
                    <DialogContentText id="alert-dialog-description">
                        <div className="dialog-body">
                            <img src={props.img} alt={props.nombre} className="dialog-img" />
                            <span className="nombre-dialog" >{props.nombre}</span>
                            <span className="formacion-dialog">{props.formacion}</span>
                            <hr className="rounded" />
                            <span className="descripcion-dialog">

                                {props.descripcion}
                            </span>
                        </div>

                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
export default MemberDialog;