import React, { useEffect, useState } from "react";
import './navbar.css';
import BasicMenu from './navmenu';
import logo from '../../assets/img/logo.png'
import { useLocation } from "react-router-dom";

const Navbar = () => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);
    const conversion = () => {
        return window.location.href = 'https://wa.link/7ucuwf';
    }
    
    const location = useLocation();
    // Obtener la ruta actual
    const currentPath = location.pathname;
    // Verificar si la ruta actual comienza con '/cursos/'
    const isOnCursosPage = currentPath.startsWith('/cursos/');
    document.addEventListener("wheel", function (event) {

        if (event.deltaY > 0) {
            // we are going down
            if (window.scrollY > 50) document.getElementById("navbar").style.top = '-5.8rem';


        }

        else {
            // we are going up
            document.getElementById("navbar").style.top = '0';

        }
    })

    return (
        <div id='navbar' style={{display: isOnCursosPage ? 'none' : null}} className={scroll ? "nav-container-white" : "nav-container"}>
            <div className="nav-logoside">
                <img className={scroll ? "nav-logo-white" : "nav-logo"} src={logo} alt="" />
            </div>
            <div className="nav-buttonsside">
                <div className="nav-pc">

                    <a href="#cover" className={scroll ? "nav-button-white" : "nav-button"}>
                        Inicio
                    </a>

                    <a href="#equipo" className={scroll ? "nav-button-white" : "nav-button"}>
                        Nuestro equipo
                    </a>

                    <a href="#cursos" className={scroll ? "nav-button-white" : "nav-button"}>
                        Cursos
                    </a>

                    <a href="#talleres" className={scroll ? "nav-button-white" : "nav-button"}>
                        Talleres
                    </a>

                    <a href="https://api.whatsapp.com/send?phone=543515917235" onClick={conversion} target="_blank" className={scroll ? "nav-button-white" : "nav-button"}>
                        Escribinos
                    </a>
                </div>

                <div className="menu-phone">
                    <BasicMenu />
                </div>
                <div className="logo-phone">
                    <img src={logo} alt="" className="logo-phone-img" />
                </div>

            </div>
        </div>
    )
}
export default Navbar;