import React from "react";
import './cursodetalle.css';
import logo from '../../assets/img/logo.png'
import { ArrowCircleLeft, Computer, SentimentVerySatisfied, WorkspacePremium } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MemberDialog from "../ourteam/member";
import { Accordion } from "react-bootstrap";

const CursoDetalle = () => {
    let { state } = useLocation();
    return (
        <div className="cursodetalle-container">
            <div className="btn-volver">
                <Link to='/#cursos' className="btn-volver-txt" onClick={() => setTimeout(() => window.location.href = "/#cursos", 100)}>
                    <ArrowCircleLeft style={{ fontSize: '4rem', marginRight: '0.6rem' }} />Volver a la página
                </Link>
            </div>
            <div className="card-curso-detalle">
                <div className="curso-detalle-titulo">
                    <span className="titulo-curso-detalle">
                        {state.curso.titulo}
                    </span>
                </div>
                <div className="video-curso-detalle">
                    <div className="descripcion-video">
                        <span className="subtitulo-curso-detalle ">sobre el curso</span>
                        <span className="desc-video-txt">
                            {state.curso.desclarga}
                        </span>
                        <div className="certificaciones">
                            <div className="certificaciones-icon-div">
                                <Computer style={{ fontSize: '3rem' }} className="certificaciones-icon" />
                                <span className="certificaciones-text">100% online</span>
                            </div>
                            <div className="certificaciones-icon-div">
                                <WorkspacePremium style={{ fontSize: '3rem' }} className="certificaciones-icon" />
                                <span className="certificaciones-text ">Certificación</span>
                            </div>
                            <div className="certificaciones-icon-div">
                                <SentimentVerySatisfied style={{ fontSize: '3rem' }} className="certificaciones-icon" />
                                <span className="certificaciones-text">Adaptado a ti</span>
                            </div>
                        </div>
                    </div>
                    <div className="video-div">
                        <span className="subtitulo-curso-detalle ">video introductivo</span>
                        <video controls autoPlay={false} className="video-curso">
                        <source src={`${state.curso.video}`} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div className="informacion-curso-detalle">
                    <div className="curso-disertantes">
                        <span className="subtitulo-curso-detalle">{state.curso.profesor.varon ? 'Profesor/es' : 'Profesora/s'}</span>
                        <div className="profesores-div-detalle">
                            <MemberDialog id={state.curso.profesor.id} nombre={state.curso.profesor.nombre} descripcion={state.curso.profesor.sobremi} formacion={state.curso.profesor.formacion} img={state.curso.profesor.img} />
                            {state.curso.profesor2 ?
                                <MemberDialog id={state.curso.profesor2.id} nombre={state.curso.profesor2.nombre} descripcion={state.curso.profesor2.sobremi} formacion={state.curso.profesor2.formacion} img={state.curso.profesor2.img} />
                                : null
                            }
                        </div>
                    </div>
                    <div className="curso-disertantes" style={{display: state.curso.tutor.nombre ? 'flex' : 'none' }}>
                        <span className="subtitulo-curso-detalle">Tutora</span>
                        <div className="profesores-div-detalle">
                            <MemberDialog id={state.curso.tutor.id} nombre={state.curso.tutor.nombre} descripcion={state.curso.tutor.sobremi} formacion={state.curso.tutor.formacion} img={state.curso.tutor.img} />
                        </div>
                    </div>
                </div>
                
                <div className="info-inscribirse">
                    <div className="acordion-info-div">
                        <Accordion className="acordion-info">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>En este curso aprenderás a</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{__html: state.curso.aprenderasa}}></div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>¿A quién va dirigido este curso?</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{__html: state.curso.vadirigido}}></div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>¿Qué incluye este curso?</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{__html: state.curso.queincluye}}></div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>¿Cómo se estructura el curso?</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{__html: state.curso.estructura}}></div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Inversión del curso</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{__html: state.curso.inversion}}></div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className="btn-inscribirse-div">
                        <span className="frase-curso">
                            <span>{state.curso.frase}</span>
                            <span className="autor-frase-curso">{state.curso.autor}</span>
                        </span>
                        <a href={`https://api.whatsapp.com/send?phone=543515917235&text=Hola%2C%20estoy%20interesado%2Fa%20en%20el%20` + state.curso.titulo.toLowerCase()} className="btn-inscribirse hook-effect">¡Accede al curso ahora, inscríbete aquí!</a>
                    </div>
                </div>



            </div>
        </div>
    )
}
export default CursoDetalle;