import React from "react";
import './testimonials.css';
import ControlledCarousel from "./carousel";



const Testimonials = () => {
    const conversion = () => {
        return window.location.href = 'https://wa.link/9ry9hg';
    }
    return (
        <div className="testimonials-container">
            <div className="testimonial-flex-container">
            <span className="section-title">Testimonios</span>
            <h1 className="phone-title-carousel">Desliza para ver</h1>
                <div className="carousel-div">
                    
                    <ControlledCarousel />
                </div>
                
            </div>
        </div>

    )
}
export default Testimonials;