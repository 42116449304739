import React, { useEffect, useRef, useState } from "react";
import './talleres.css';
import { Link } from "react-router-dom";
import mapaternindad from '../../assets/img/talleres/mapaternidad.jpg';
import abundancia from '../../assets/img/talleres/abundancia.jpg';
import kilosemocionales from '../../assets/img/talleres/kilosemocionales.jpg';
import terapiapareja from '../../assets/img/talleres/terapiapareja.jpg';
import amarsoledad from '../../assets/img/talleres/amarsoledad.jpg';
import { WhatsApp } from "@mui/icons-material";
import ReactElasticCarousel from "react-carousel-elasticss";

const Talleres = () => {
    const [itemsToShop, setItemsToShop] = useState(3);
    const [heightCard, setHeightCard] = useState(null);
    const divLargoRef = useRef(null);


    useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 600px)');
      const elementos = document.querySelectorAll('.taller-card');
      if (divLargoRef.current) {
          setHeightCard(divLargoRef.current.clientHeight);
          elementos.forEach(elemento => {
              if(elemento.style.height < heightCard)
                  elemento.style.height = `${heightCard}px`;
            });
        }
      const handleResize = () => {
        if (mediaQuery.matches) {
          setItemsToShop(1);
        } else {
          setItemsToShop(3);
        }
      };
  
      handleResize(); // Llama a la función al inicio para establecer el valor inicial
  
      mediaQuery.addEventListener('change', handleResize);
  
      return () => {
        mediaQuery.removeEventListener('change', handleResize);
      };
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1
    }
    const talleres = [
        { id: 1, titulo: "TALLER MA-PATERNIDAD HOLÍSTICA", descripcion: "Aprende sobre los desórdenes sistémicos que afectan a tus hijos, cómo identificar los patrones de repetición, para reconocerlos y liberarlos a traves de la resignificación. Tendrás ejercicios prácticos y herramientas para poder llevarlo a cabo. Descubre tus heridas de la infancia, con el fin de poder reconocerlas y gestionarlas, para una crianza más consciente y libre. ¡Únete a nosotros para este taller transformador!", image: mapaternindad },
        { id: 2, titulo: "TALLER ABUNDANCIA, TEORÍA DEL DESDOBLAMIENTO DEL TIEMPO", descripcion: "Descubre cómo manifestar correctamente tu realidad ideal a través de la teoría del desdoblamiento del tiempo. Aprende bases sistémicas, energéticas, del alma y físicas para crear tu vida según tus deseos. El dinero es un estado de conciencia cuántica, a través de distintos tipos de estados emocionales y órdenes sistémicos, podemos convertirnos en imanes cuánticos de todo lo que queramos manifestar. ¡Únete a este taller transformador!", image: abundancia, largo: true },
        { id: 3, titulo: "TALLER KILOS EMOCIONALES", descripcion: "Este taller te guiará a través de las 6 posibles decodificaciones y conflictos emocionales que subyacen a la obesidad/sobrepeso. Si has probado todas las herramientas convencionales sin éxito, es hora de escuchar lo que tu cuerpo e incomodidad están tratando de decirte. Aprenderás cómo identificar la raíz emocional detrás de estos problemas y realizar ejercicios para transformar esas creencias limitantes.", image: kilosemocionales },
        { id: 4, titulo: "TALLER TERAPIA DE PAREJA CON ORIENTACIÓN HOLÍSTICA", descripcion: "Taller para fortalecer y enriquecer tu relación desde una perspectiva sistemica. Exploraremos cómo influyen otros aspectos de la vida, como los desordenes sistemicos, los lenguajes del amor, el movimiento interrumpido, apegos emocionales, y mucho más. Aprenderás herramientas prácticas y técnicas efectivas para mejorar la comunicación, resolver conflictos y cultivar una conexión más profunda y significativa con tu pareja. ¡Únete a nosotros y comienza el viaje hacia una relación sana!", image: terapiapareja},
        { id: 5, titulo: "TALLER AMAR LA SOLEDAD: Especial de codependencia emocional", descripcion: "A lo largo del taller, exploraremos las raíces y dinámicas de la codependencia emocional, así como las formas en que afecta nuestras relaciones y bienestar personal. Aprenderemos a reconocer patrones de comportamiento codependientes y a desarrollar estrategias para cultivar una relación más saludable con nosotros mismos y con los demás. ¡Únete a nosotros en este viaje hacia la autocomprensión y el crecimiento personal! Amar tu soledad.", image: amarsoledad },
    ]
    return (
        <div className="talleres-container" id="talleres">
            <span className="section-title center">
                Nuestros talleres
            </span>
            <h1 className="phone-title-carousel inverted-color">Desliza para ver</h1>
            <div className="talleres-list ">
            
                <ReactElasticCarousel
                itemsToShow={itemsToShop}
                showArrows={itemsToShop != 1}
                >
                {
                    talleres.map(taller => (

                        <div className="taller-card"  ref={taller.largo ? divLargoRef : null} key={taller.id}>
                            <div className="taller-img" id={taller.id + 'img'}>
                                <img src={taller.image} alt="" className="img-taller" />
                            </div>
                            <div className="taller-info" id={taller.id + 'info'}>
                                <div className="taller-title">
                                    {taller.titulo}
                                </div>

                                <div className="taller-desc">
                                    {taller.descripcion}
                                </div>
                                <div className="taller-profes-ul">
                                    <div className="btn-info-div">
                                        <a href={`https://api.whatsapp.com/send?phone=543515917235&text=Hola%2C%20estoy%20interesado%2Fa%20en%20saber%20más%20sobre%20el%20` + taller.titulo.toLowerCase()} className="btn-info"><icono><WhatsApp sx={{ fontSize: 25, color: "#ffffff", marginBottom: '0.2rem' }} /></icono>Más información</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                </ReactElasticCarousel>
            </div>
        </div>
    )
}
export default Talleres;