
import './App.css';
import Navbar from './components/navbar/navbar';
import React, {useState, useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import WhatsApp from './components/whatsapp-button/whatsapp';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import Homepage from './components/homepage/homepage';
import CursoDetalle from './components/cursodetalle/cursodetalle';
import { useLocation } from "react-router-dom";


function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {

AOS.init({
  duration: 500
});
/*
<Aboutus />
    <Socialmedia />
    
    <Google />
    
    <Caller />
    <Emailm />
    <Plans />
    <Taller />
    <TallerDos />
    
    <Footer />
    
    */
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/cursos/:id" element={<CursoDetalle />} />
      </Routes>
      <WhatsApp />
    </Router>
  );
}

export default App;
