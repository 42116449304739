import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import './navbar.css';

const BasicMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const cover = () => {
    const element = document.getElementById('cover');
    if (element) {
      handleClose();
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 300);
    }
  }
  const cursos = () => {
    const element = document.getElementById('cursos');
    if (element) {
      handleClose();
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 300);
    }
  }
  const talleres = () => {
    const element = document.getElementById('talleres');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      handleClose();
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 300);
    }
  }
  const equipo = () => {
    const element = document.getElementById('equipo');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      handleClose();
      setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 300);
    }
  }
  const wpButton = () => {
    window.location.href = "https://api.whatsapp.com/send?phone=543515917235";
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon color='inherit' className='icon-menu' />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className='menu-phone'>
        <MenuItem onClick={cover}>Inicio</MenuItem>
          <MenuItem onClick={equipo}>Nuestro equipo</MenuItem>
          <MenuItem onClick={cursos}>Cursos</MenuItem>
          <MenuItem onClick={talleres}>Talleres</MenuItem>
          <MenuItem onClick={wpButton}>Escribinos</MenuItem>
        </div>

      </Menu>
    </div>
  );
}
export default BasicMenu;