import React from "react";
import './cover.css'
import guada from '../../assets/img/cover.png';
import { MenuBook, RecordVoiceOver, School, WhatsApp } from "@mui/icons-material";
import { Icon, Tooltip } from "@mui/material";

const Cover = () => {
    const goServices = () => {
        const element = document.getElementById('webdev');
        if (element) {
            setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), 100);
        }
    }
    //<span href='#webdev'onClick={goServices} className="see-services-button">Ver servicios</span>
    return (
        <section id="cover">
            <div className="cover-container">
                <div className="cover-flex-container">
                    <div className="cover-title-h1">
                        <h1 className="title-cover">
                            Centro holístico y academia online Regresa a ti
                        </h1>
                        <h1 className="subtitle-h1">
                            Si estás buscando una sanación profunda y un cambio en tu vida, y además generar ingresos reales mientras
                            acompañas a otras personas en su proceso de sanación, tenemos lo que necesitas
                        </h1>
                        <a href="https://api.whatsapp.com/send?phone=543515917235" className="boton-wpp-cover"> <WhatsApp sx={{ fontSize: 30, marginBottom: 0.4, marginRight: 1 }} />Hacer una consulta</a>
                        <img src={guada} className="person-cover-img" alt="" />
                        
                        <div className="cover-nav">
                            <Tooltip title="Hacer una consulta" placement="bottom" arrow>
                                <a href="https://api.whatsapp.com/send?phone=543515917235" className="button-cover-nav">
                                    <WhatsApp sx={{ fontSize: 40 }} />
                                </a>
                            </Tooltip>
                            <Tooltip title="Nuestros cursos" placement="bottom" arrow>
                                <a href="#cursos" className="button-cover-nav">
                                    <School sx={{ fontSize: 40 }} />
                                </a>
                            </Tooltip>

                            <Tooltip title="Nuestros talleres" placement="bottom" arrow>
                                <a href="#talleres" className="button-cover-nav">
                                    <MenuBook sx={{ fontSize: 40 }} />
                                </a>
                            </Tooltip>
                            <Tooltip title="Consultas terapéuticas" placement="bottom" arrow>
                                <a href="https://regresaati.com" className="button-cover-nav">
                                    <RecordVoiceOver sx={{ fontSize: 40 }} />
                                </a>
                            </Tooltip>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
export default Cover;