
import React, {useEffect, useState} from 'react';
import './carousel.css'
import quote from '../../assets/img/quote.png'
import ReactElasticCarousel from 'react-carousel-elasticss';

const ControlledCarousel = () => {
    // Datos de ejemplo para los miembros del equipo
    const [mostrarArrows, setMostrarArrows] = useState(true);

    useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 600px)');
  
      const handleResize = () => {
        if (mediaQuery.matches) {
          setMostrarArrows(false);
        } else {
          setMostrarArrows(true);
        }
      };
  
      handleResize(); // Llama a la función al inicio para establecer el valor inicial
  
      mediaQuery.addEventListener('change', handleResize);
  
      return () => {
        mediaQuery.removeEventListener('change', handleResize);
      };
    }, []);

    const testimonios = [
        {
            id: 1,
            nombre: 'Rocío Quinteros, México',
            descripcion: `Estoy encantada con las formaciones, son una experiencia de autoconocimiento, es un placer poder conseguir ingresos de algo tan mágico como el servicio espiritual, y sanar mientras acompañamos a otros a su sanación, los seguiré eligiendo para mis futuras formaciones`,
        },
        {
            id: 2,
            nombre: 'Wanda Sofía López, Colombia',
            descripcion: `No encontraba mi vocación, si bien tengo mi profesión y carrera, no era felíz haciendo lo que hacia, primero tomé consultas terapéuticas y encontré allí una gran satisfacción, conociendo el excelente trabajo y formación que tienen los terapeutas desde mi propia sanación, aposté a capacitarme con ellos y fue una de las mejores decisiones de mi vida, gracias.`,
        },
        {
            id: 3,
            nombre: 'Rubén Carlos Chiquillo, Chile',
            descripcion: `Desde la primera consulta han contestado mis dudas, manejan una calidad humana muy real y presente, flexibilidad pero rigidez para que realmente podamos capacitarnos, me tocó un grupo hermoso con el cual hasta hoy compartimos experiencias y nos facilitamos sanación entre nosotros, casi todos hemos conseguido generar de esta hermosa vocación de servicio, ingresos estables, estoy muy felíz!`,
        },
        {
            id: 4,
            nombre: 'Catalina Magliado, Argentina',
            descripcion: `Desde mi primera experiencia en constelaciones familiares, supe que había algo especial en la metodología, pero debía sanar muchas cosas primero para superar la barrera de los miedos y prejuicios y animarme a dedicarme a esto, sin dudas Regresa a ti fue mi compañía y guía, millones de gracias por eso`,
        },
    ]
    return (
        <ReactElasticCarousel
            showStatus={false}
            showIndicators={true}
            showThumbs={false}
            autoPlay={true}
            interval={7000}
            itemsToShow={1}
            showArrows={false}
            infiniteLoop={true}
            stopOnHover={true}
            className='carousel-box'
        >
            {testimonios.map(testimonio => (
                <div key={testimonio.id} className='card-testimonial'>
                    <div className="card-description">
                        <div className="quote-img">
                            <img src={quote} alt="" className='quote' />
                        </div>

                        <p className='card-description-text'>
                            {testimonio.descripcion}</p>
                    </div>

                    <p className='card-name'>{testimonio.nombre}</p>
                </div>
            ))}
        </ReactElasticCarousel>
    );
}

export default ControlledCarousel;